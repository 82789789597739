import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { CompliteOrderModal } from '../Modals/CompliteOrderModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ProductModal = ({ isOpen, closeModal, title, content, product }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);

    const PRODUCT_LANG = Language(product.UA, product.RU);

    const [activeImage, setActiveImage] = useState([]);

    useEffect(() => {
        setActiveImage(!!product?.gallery?.length && product?.gallery[0]);
    }, [isOpen]);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        $('#modal_gallery').owlCarousel({
            responsive: {
                0: {
                    items: 6,
                    dots: true,
                    nav: false,
                    margin: 10,
                },
                640: {
                    items: 5,
                    dots: true,
                    nav: false,
                    margin: 0,
                },
                750: {
                    items: 5,
                    dots: true,
                    nav: false,
                    margin: 0,
                },
                960: {
                    items: 6,
                    dots: true,
                    nav: false,
                },
                1211: {
                    items: 6,
                    dots: true,
                    nav: false,
                    margin: 10,
                },
            },
        });
    }, [isOpen, activeImage]);

    // Modal
    const [isModalOpenOrder, setModalOpenOrder] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const openModalOrder = () => {
        setModalOpenOrder(true);
        navigate('/modal_send');
    };
    const closeModalOrder = () => {
        setModalOpenOrder(false);
        if (location.pathname === '/modal_send') {
            navigate(-1);
        }
    };

    useEffect(() => {
        const handlePopstate = () => {
            if (isModalOpenOrder) {
                setModalOpenOrder(false);
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isModalOpenOrder]);
    // Modal

    if (!isOpen) return null;
    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ backgroundColor: 'red' }}>
                <div className="popup popup-product jqm-init ">
                    <div onClick={closeModal} className="close_box">
                        <button className="close" />
                    </div>
                    <div className="box product">
                        <div className="labels">
                            {PRODUCT_LANG?.markers.map((el, key) => {
                                if (el === Language('Хіт', 'Хит'))
                                    return (
                                        <div key={key} className="label" style={{ backgroundColor: '#2992d9' }}>
                                            {el}
                                        </div>
                                    );
                                if (el === Language('Новинка', 'Новинка'))
                                    return (
                                        <div key={key} className="label" style={{ backgroundColor: '#ff0000' }}>
                                            {el}
                                        </div>
                                    );
                                if (el === Language('Акція', 'Акция'))
                                    return (
                                        <div key={key} className="label" style={{ backgroundColor: '#ffc438' }}>
                                            {el}
                                        </div>
                                    );
                            })}
                        </div>
                        <div className="columns">
                            <div className="left">
                                <div className="photo">
                                    {activeImage ? (
                                        activeImage?.path.slice(-4) === '.MOV' || activeImage?.path.slice(-4) === '.mp4' ? (
                                            <video controls poster={product?.images[0]?.path} style={{ maxHeight: 350, width: '100%' }}>
                                                <source src={activeImage?.path} type="video/mp4" />
                                                <source src={activeImage?.path} type="video/quicktime" />
                                                Ваш браузер не підтримує відео.
                                            </video>
                                        ) : (
                                            <img src={activeImage?.path} alt="" />
                                        )
                                    ) : (
                                        <span>No image available</span>
                                    )}
                                </div>
                                <div className="photo-thumbs">
                                    <div className="slider">
                                        <div className="owl-carousel" id="modal_gallery">
                                            {product?.gallery.map((pic, ind) => {
                                                const active = activeImage?.path === pic?.path && 'active';
                                                if (pic?.path.slice(-4) === '.MOV' || pic?.path.slice(-4) === '.mp4')
                                                    return (
                                                        <video
                                                            key={ind}
                                                            poster={product?.images[0]?.path}
                                                            className={`item ${active}`}
                                                            onClick={() => setActiveImage(pic)}
                                                            src={pic?.path || ''}></video>
                                                    );
                                                return (
                                                    <LazyLoadImage
                                                        alt=""
                                                        height={82}
                                                        key={ind}
                                                        onClick={() => setActiveImage(pic)}
                                                        className={`item ${active}`}
                                                        effect="blur"
                                                        src={pic?.url_small}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="top">
                                    <h2 className="product-title">{PRODUCT_LANG?.name}</h2>
                                    {!!activeImage.originalname && (
                                        <div className="article">Артикул: {activeImage.originalname?.split('.')[0] || '----'}</div>
                                    )}

                                    <div className="properties">
                                        <div className="property">
                                            <span>Вид:</span>
                                            {PRODUCT_LANG?.look}
                                        </div>
                                        <div className="property">
                                            <span>Модель:</span>
                                            {PRODUCT_LANG?.model}
                                        </div>
                                        {!!activeImage?.color && (
                                            <div className="property">
                                                <span>{Language('Колір', 'Цвет')}:</span>
                                                {Language(activeImage?.color.split('/')[0], activeImage?.color.split('/')[1])}
                                            </div>
                                        )}
                                        {!!PRODUCT_LANG?.system && (
                                            <div className="property">
                                                <span>{Language('Система', 'Система')}:</span>
                                                {PRODUCT_LANG?.system}
                                            </div>
                                        )}
                                        <div className="property">
                                            <span>{Language('Тип тканини', 'Тип ткани')}:</span>
                                            {PRODUCT_LANG?.fabric_type}
                                        </div>
                                        <div className="property">
                                            <span>Бренд: </span> {PRODUCT_LANG?.brand}
                                        </div>
                                        <div className="property">
                                            <span>{Language('Країна виробник', 'Страна производитель')}:</span>
                                            {PRODUCT_LANG?.madein}
                                        </div>
                                        <div className="property">
                                            <span>{Language('Тип кріплення', 'Тип крепежа')}:</span>
                                            {PRODUCT_LANG?.mounting_type}
                                        </div>
                                        <div className="property">
                                            <span>{Language('Ширина тканини', 'Ширина ткани')}:</span>
                                            {PRODUCT_LANG?.sizes?.width}
                                        </div>
                                        <div className="property">
                                            <span>{Language('Висота тканини', 'Высота ткани')}:</span>
                                            {PRODUCT_LANG?.sizes?.height}
                                        </div>
                                    </div>

                                    <div className="preview-text">
                                        <h3 className="preview-text-title">{Language('Комплектація', 'Комплектация')}</h3>
                                        {PRODUCT_LANG?.equipments}
                                    </div>

                                    {!!PRODUCT_LANG?.note && (
                                        <div className="preview-text">
                                            <h3 className="preview-text-title">{Language('Опис', 'Описание')}</h3>
                                            {PRODUCT_LANG?.note}
                                        </div>
                                    )}
                                </div>

                                <div className="price-and-btn">
                                    <div className="price currency-rub">
                                        <span className="old">
                                            <span className="before_price">від </span>
                                            {(PRODUCT_LANG?.pricce / (1 - parseFloat(PRODUCT_LANG?.discount) / 100))
                                                .toFixed(0)
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        </span>
                                        <span className="new">
                                            <span className="before_price">від </span>
                                            {Number(PRODUCT_LANG?.pricce)
                                                .toFixed(0)
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        </span>
                                    </div>
                                    <Link onClick={openModalOrder} className="btn">
                                        Замовити
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CompliteOrderModal
                    isOpen={isModalOpenOrder}
                    closeModal={closeModalOrder}
                    selectedProduct={{ image: activeImage, name: PRODUCT_LANG?.name }}
                />
            </Modal>
        </>
    );
};
