import { useLocation, Link } from 'react-router-dom';

export const Bots = ({ BOTS }) => {
    const { pathname } = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => BOTS?.fields.find((el) => el._id === fieldId);

    const TITLE = findFiel('652bff1dba63b8929f4c1b6b');
    const TELEGRAM_BOT = findFiel('652bffacba63b8929f4c1b6d');
    const VIBER_BOT = findFiel('652bffc5ba63b8929f4c1b6f');
    const IMAGE = findFiel('652bffe7ba63b8929f4c1b74');

    return (
        <section className="price-list" id="bots">
            <div className="container">
                <div className="content">
                    <div className="info">
                        <img src={IMAGE?.content?.images[0]?.path} className="price-list_img" alt={IMAGE?.content?.images[0]?.originalname} />
                    </div>

                    <div className="fields price_info">
                        <h2 className="subtitle white price-list_title">{Language(TITLE.content.UA.text, TITLE.content.RU.text)}</h2>

                        <div className="price-list_btn_box">
                            <Link to={Language(TELEGRAM_BOT.content.UA.link, TELEGRAM_BOT.content.RU.link)} className="btn color submit price_btn">
                                {Language(TELEGRAM_BOT.content.UA.title, TELEGRAM_BOT.content.RU.title)}
                            </Link>
                            <Link to={Language(VIBER_BOT.content.UA.link, VIBER_BOT.content.RU.link)} className="btn color submit price_btn">
                                {Language(VIBER_BOT.content.UA.title, VIBER_BOT.content.RU.title)}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
