import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const VideoWorks = ({ VIDEO_WORKS }) => {
    const { pathname } = useLocation();
    const findField = (fieldId) => VIDEO_WORKS?.fields.find((el) => el._id === fieldId);
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);

    const [activeIndex, setActiveIndex] = useState(null);
    const videoRefs = useRef([]);

    const handleVideoClick = (index) => {
        if (activeIndex === index) {
            const videoElement = videoRefs.current[index];

            if (videoElement) {
                if (!videoElement.paused) {
                    videoElement.pause();
                } else {
                    videoElement.play();
                }
            }
        } else {
            const currentVideoIndex = activeIndex;
            setActiveIndex(index);

            if (currentVideoIndex !== null) {
                const currentVideoElement = videoRefs.current[currentVideoIndex];
                if (currentVideoElement) {
                    currentVideoElement.pause();
                }
            }
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-undef
        $('#carousel-RCkZO').owlCarousel({
            margin: 20,
            // loop: true,
            dots: true,
            dotsContainer: '#carousel-dots-RCkZO',
            navText: ["<div class='prev icon icon-back'></div>", "<div class='next icon icon-forward'></div>"],
            navContainer: '#gallery-navigation',
            responsive: {
                0: {
                    items: 1,
                    nav: false,
                },
                640: {
                    items: 2,
                    nav: true,
                },
                960: {
                    items: 3,
                    nav: true,
                },
                1100: {
                    items: 4,
                    nav: true,
                },
            },
        });
    }, []);

    const TITLE = findField('652a4176ba63b8929f4c193d');
    const SUB_TITLE = findField('652a41a2ba63b8929f4c193f');

    return (
        <div className="container" id="view_work">
            <section className="gallery">
                <div className="content">
                    <div className="text">
                        <h2 className="subtitle">{Language(TITLE.content.UA.text, TITLE.content.RU.text)}</h2>
                        <div className="desc">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: Language(SUB_TITLE.content.UA.rich_text, SUB_TITLE.content.RU.rich_text),
                                }}
                            />
                        </div>
                    </div>
                    <div className="owl-nav" id="gallery-navigation"></div>
                </div>

                <div className="slider">
                    <div className="owl-carousel" id="carousel-RCkZO">
                        {VIDEO_WORKS?.fields.map(
                            (field, index) =>
                                field.field_type === 'Video' && (
                                    <div key={index} className="item">
                                        <div className="gradient">
                                            <div className={`gallery_video-box`} onClick={() => handleVideoClick(index)}>
                                                <video
                                                    // controls
                                                    className="gallery_video"
                                                    muted={activeIndex !== index}
                                                    autoPlay={activeIndex !== index}
                                                    loop={true}
                                                    id={`video-${index}`}
                                                    ref={(el) => (videoRefs.current[index] = el)}
                                                    src={field?.content?.video[0]?.path}></video>
                                            </div>
                                            <div className="name">{Language(field.content.UA.title, field.content.RU.title)}</div>
                                        </div>
                                    </div>
                                ),
                        )}
                    </div>

                    <div className="owl-dots" id="carousel-dots-RCkZO"></div>
                </div>
            </section>
        </div>
    );
};
