import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProductModal } from '../ProductModal/ProductModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isMobile } from 'react-device-detect';

export const PopularMaterials = ({ POPULAR_MATERIALS, productsData, isLoadingProducts }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findField = (fieldId) => POPULAR_MATERIALS?.fields.find((el) => el._id === fieldId);
    const Products = productsData.filter((item) => item.UA && item.UA.markers && item.UA.markers.includes('Хіт'));
    const [isProductActive, setProductActive] = useState([]);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = (product) => {
        setModalOpen(true);
        setProductActive(product);
        navigate('/modal');
    };

    const closeModal = () => {
        setModalOpen(false);
        setProductActive([]);
        if (location.pathname === '/modal') {
            navigate(-1); // Повертаємося на попередню сторінку
        }
    };

    useEffect(() => {
        const handlePopstate = () => {
            if (isModalOpen) {
                setModalOpen(false);
            }
        };
        window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isModalOpen]);

    useEffect(() => {
        !!Products.length &&
            // eslint-disable-next-line no-undef
            $('#top-catalog-qLcTY').owlCarousel({
                navText: ["<div class='prev icon icon-back'></div>", "<div class='next icon icon-forward'></div>"],
                navContainer: '#catalog-top-navigation',
                dotsContainer: '#catalog-top-dots',
                responsive: {
                    0: {
                        items: 1,
                        // loop: true,
                        dots: true,
                        nav: false,
                        margin: 25,
                    },
                    640: {
                        items: 2,
                        // loop: true,
                        dots: true,
                        nav: false,
                        margin: 0,
                    },
                    960: {
                        items: 2,
                        // loop: true,
                        dots: true,
                        nav: true,
                    },
                    1211: {
                        items: 3,
                        // loop: true,
                        dots: true,
                        nav: true,
                    },
                },
            });
    }, [Products]);

    const TITLE = findField('652bdb76ba63b8929f4c1ad9');
    const DESCRIPTION = findField('652bdbb0ba63b8929f4c1adb');

    return (
        <>
            <ProductModal isOpen={isModalOpen} closeModal={closeModal} product={isProductActive} />

            <div className="container" id="popular_material">
                <section className="best">
                    <div className="content">
                        <h2 className="subtitle">{Language(TITLE?.content.UA.text, TITLE?.content.RU.text)}</h2>
                        <div className="desc">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: Language(DESCRIPTION?.content.UA.rich_text, DESCRIPTION?.content.RU.rich_text),
                                }}
                            />
                        </div>
                        <div className="owl-nav" id="catalog-top-navigation"></div>
                        <div className="owl-dots" id="catalog-top-dots"></div>
                    </div>

                    <div className="slider">
                        <div className="owl-carousel" id="top-catalog-qLcTY">
                            {Products.map((product, ind) => {
                                const PRODUCT = Language(product.UA, product.RU);

                                return (
                                    <div key={ind} className="owl-item">
                                        <div className="product">
                                            <div className="label hit">{Language('Хіт', 'Хит')}</div>

                                            <Link onClick={() => openModal(product)} className="img">
                                                <LazyLoadImage
                                                    alt={product?.images[0]?.originalname}
                                                    height={isMobile ? 340 : 293}
                                                    placeholderSrc={product?.images[0]?.url_small}
                                                    effect="blur"
                                                    src={product?.images[0]?.path || './img/icons/no-image.png'}
                                                />
                                            </Link>

                                            <Link onClick={() => openModal(product)} className="name">
                                                {PRODUCT.name}
                                            </Link>

                                            <div className="text">
                                                {Language('Колір', 'Цвет')}:
                                                {product?.colors.map((color, ind) => (
                                                    <span key={ind} className="color" style={{ background: color.color }}></span>
                                                ))}
                                            </div>
                                            <div className="price-and-btn">
                                                <div className="price currency-rub">
                                                    <span className="old">
                                                        <span className="before_price--catalog">від </span>
                                                        {(PRODUCT?.pricce / (1 - parseFloat(PRODUCT?.discount) / 100))
                                                            .toFixed(0)
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    </span>
                                                    <span className="new">
                                                        <span className="before_price--catalog">від </span>
                                                        {Number(PRODUCT.pricce)
                                                            .toFixed(0)
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    </span>
                                                </div>
                                                <Link onClick={() => openModal(product)} className="btn">
                                                    {Language('Купити', 'Купить')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};
