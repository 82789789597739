import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProductModal } from '../ProductModal/ProductModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { isMobile } from 'react-device-detect';

const CATEGORY_UA = [
    { 'День-ніч': ['Однотонні', 'Фактурні', 'BleackOut'] },
    { 'Рулонні штори': ['Однотонні', 'Фактурні', 'Принт', 'BleackOut', 'Джутові', 'Бамбукові'] },
    { 'Римські штори': ['Однотонні', 'Фактурні', 'Принт', 'BleackOut'] },
    { 'Горизонтальні жалюзі': ['Алюмінієві', "Дерев'яні", 'Бамбукові'] },
    { 'Вертикальні жалюзі': ['Всі'] },
    { Пліссе: ['Всі'] },
];
const CATEGORY_RU = [
    { 'День-ночь': ['Однотонные', 'Фактурные', 'BleackOut'] },
    {
        'Рулонные шторы': ['Однотонные', 'Фактурные', 'Принт', 'BleackOut', 'Джутовые', 'Бамбуковые'],
    },

    { 'Римские шторы': ['Однотонные', 'Фактурные', 'Принт', 'BleackOut'] },
    { 'Горизонтальные жалюзи': ['Алюминиевые', 'Деревянные', 'Бамбуковые'] },
    { 'Вертикальные жалюзи': ['Все'] },
    { Плиссе: ['Все'] },
];

export const Catalog = ({ CATALOG, productsData, isLoadingProducts }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const isUaLanguage = pathname === '/';
    const Language = (ua, ru) => (pathname === '/' ? ua : ru);
    const findFiel = (fieldId) => CATALOG?.fields.find((el) => el._id === fieldId);

    const TITLE = findFiel('652bee60ba63b8929f4c1b2b');
    const DESCRIPTION = findFiel('652bee88ba63b8929f4c1b2d');
    const BUTTON_TEXT = findFiel('652beeb1ba63b8929f4c1b2f');

    const [CATALOG_LOCAL, setCatalogLocal] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const selectCategoriesByLanguage = () => {
        const categoryUA = 'День-ніч';
        const categoryRU = 'День-ночь';
        const subCategoryUA = 'Однотонні';
        const subCategoryRU = 'Однотонные';

        setSelectedCategory(Language(categoryUA, categoryRU));
        setSelectedSubCategory(Language(subCategoryUA, subCategoryRU));
    };

    const sortableProductsData = productsData.sort((p, n) => p.UA.pricce - n.UA.pricce);

    useEffect(() => {
        selectCategoriesByLanguage();
        setCatalogLocal(Language(CATEGORY_UA, CATEGORY_RU));
    }, [isUaLanguage]);

    // Фільтруємо тканини, щоб отримати лише ті, які належать до заданої категорії
    function checkIfCategoryHasProducts(categoryToCheck) {
        const productsInCategory = sortableProductsData?.filter((product) => Language(product.UA.look, product.RU.look) === categoryToCheck);
        // Перевіряємо, чи є товари в категорії
        return productsInCategory?.length > 0;
    }

    // Фільтруємо тканини, щоб отримати лише ті, які належать до заданої під категорії
    const checkIfSubCategoryHasProducts = (category, subCategory) => {
        const productsInCategory = sortableProductsData?.filter((product) => Language(product.UA.look, product.RU.look) === category);
        const productsInSubCategory = productsInCategory?.filter((product) => Language(product.UA.model, product.RU.model) === subCategory);
        return productsInSubCategory?.length > 0;
    };

    // Функція для фільтрації об'єктів
    const filterProducts = (products) => {
        if (selectedCategory && selectedSubCategory) {
            return products?.filter(
                (product) =>
                    Language(product.UA.look, product.RU.look) === selectedCategory &&
                    Language(product.UA.model, product.RU.model) === selectedSubCategory,
            );
        } else if (selectedCategory) {
            return products?.filter((product) => Language(product?.UA?.look, product?.RU?.look) === selectedCategory);
        } else {
            return products;
        }
    };

    // Функція для вибору першої підкатегорії при зміні категорії
    const selectFirstSubCategory = (category) => {
        if (CATALOG_LOCAL.find((obj) => Object.keys(obj)[0] === category)) {
            const subCategories = CATALOG_LOCAL.find((obj) => Object.keys(obj)[0] === category)[category];
            const firstSubCategoryWithProducts = subCategories.find((subCategory) => checkIfSubCategoryHasProducts(category, subCategory));
            const firstSubCategory = firstSubCategoryWithProducts || subCategories[0];
            setSelectedSubCategory(firstSubCategory);
        }
    };

    // Load More
    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);
    // Розділіть всі елементи на частини по 8 елементів кожна
    const totalPageCount = Math.ceil(filterProducts(sortableProductsData).length / itemsPerPage);
    // Виберіть елементи для поточної сторінки
    const displayedItems = filterProducts(sortableProductsData).slice(0, currentPage * itemsPerPage);

    const loadMore = () => {
        if (currentPage < totalPageCount) {
            setCurrentPage(currentPage + 1);
        }
    };
    // ./Load More

    // MODAL
    const [isProductActive, setProductActive] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = (product) => {
        setModalOpen(true);
        setProductActive(product);
        navigate('/modal');
    };

    const closeModal = () => {
        setModalOpen(false);
        setProductActive([]);
        if (location.pathname === '/modal') {
            navigate(-1); // Повертаємося на попередню сторінку
        }
    };

    useEffect(() => {
        const handlePopstate = () => {
            if (isModalOpen) {
                setModalOpen(false);
            }
        };
        window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [isModalOpen]);
    // ./MODAL

    return (
        <>
            <ProductModal isOpen={isModalOpen} closeModal={closeModal} product={isProductActive} />

            <section className="catalog" id="catalog">
                <div className="container">
                    <h2 className="subtitle">{Language(TITLE?.content?.UA?.text, TITLE?.content?.RU?.text)}</h2>
                    <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                            __html: Language(DESCRIPTION?.content?.UA?.rich_text, DESCRIPTION?.content?.RU?.rich_text),
                        }}
                    />

                    <div className="tabs center">
                        {CATALOG_LOCAL?.map((categoryObj) => {
                            const category = Object.keys(categoryObj)[0];
                            const isCategoryActive = category === selectedCategory;
                            const categoryHasProducts = checkIfCategoryHasProducts(category); // Функція для перевірки, чи є товари в категорії
                            if (categoryHasProducts) {
                                return (
                                    <Link
                                        key={category}
                                        className={`tab ${isCategoryActive ? 'active' : ''}`}
                                        onClick={() => {
                                            setSelectedCategory(category);
                                            selectFirstSubCategory(category);
                                        }}>
                                        {category}
                                    </Link>
                                );
                            } else {
                                return null; // Якщо немає товарів в категорії, не відображаємо категорію
                            }
                        })}
                    </div>

                    <div className="tab-content active" id="tab-10">
                        <div className="tags center">
                            {/* Відображення кнопок підкатегорій */}
                            {selectedCategory &&
                                CATALOG_LOCAL?.find((obj) => Object.keys(obj)[0] === selectedCategory)[selectedCategory]?.map((subCategory) => {
                                    const subCategoryHasProducts = checkIfSubCategoryHasProducts(selectedCategory, subCategory); // Функція для перевірки, чи є товари в категорії
                                    if (subCategoryHasProducts) {
                                        return (
                                            <Link
                                                key={subCategory}
                                                className={`tag ${selectedSubCategory === subCategory ? 'active' : ''}`}
                                                onClick={() => setSelectedSubCategory(subCategory)}>
                                                {subCategory}
                                            </Link>
                                        );
                                    } else {
                                        return null; // Якщо немає товарів в категорії, не відображаємо категорію
                                    }
                                })}
                        </div>

                        <div className="tag-content active">
                            <div className="items">
                                {displayedItems.map((product, ind) => {
                                    const PRODUCT = Language(product?.UA, product?.RU);
                                    // console.log('PRODUCT', displayedItems);
                                    return (
                                        <div key={ind} className="product">
                                            <div className="labels">
                                                {PRODUCT?.markers.map((el, key) => {
                                                    if (el === Language('Хіт', 'Хит'))
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="label"
                                                                style={{
                                                                    backgroundColor: '#2992d9',
                                                                }}>
                                                                {el}
                                                            </div>
                                                        );
                                                    if (el === Language('Новинка', 'Новинка'))
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="label"
                                                                style={{
                                                                    backgroundColor: '#ff0000',
                                                                }}>
                                                                {el}
                                                            </div>
                                                        );
                                                    if (el === Language('Акція', 'Акция'))
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="label"
                                                                style={{
                                                                    backgroundColor: '#ffc438',
                                                                }}>
                                                                {el}
                                                            </div>
                                                        );
                                                })}
                                            </div>

                                            <Link className="img" onClick={() => openModal(product)}>
                                                <LazyLoadImage
                                                    alt=""
                                                    height={isMobile ? 340 : 293}
                                                    placeholderSrc={product?.images[0]?.url_small}
                                                    effect="blur"
                                                    src={product?.images[0]?.path || './img/icons/no-image.png'}
                                                />
                                                {/* <img src={product?.images[0]?.path || './img/icons/no-image.png'} alt="TravelSafe FEATHERPACK" /> */}
                                            </Link>

                                            <Link className="name" onClick={() => openModal(product)}>
                                                {PRODUCT?.name}
                                            </Link>

                                            <div className="text">
                                                {Language('Колір', 'Цвет')}:
                                                {product?.colors.map((color, ind) => (
                                                    <span key={ind} className="color" style={{ background: color.color }}></span>
                                                ))}
                                            </div>
                                            <div className="price-and-btn">
                                                <div className="price currency-rub">
                                                    <span className="old">
                                                        <span className="before_price--catalog">від </span>
                                                        {(PRODUCT?.pricce / (1 - parseFloat(PRODUCT?.discount) / 100))
                                                            .toFixed(0)
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    </span>
                                                    <span className="new">
                                                        <span className="before_price--catalog">від </span>
                                                        {Number(PRODUCT?.pricce)
                                                            .toFixed(0)
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                    </span>
                                                </div>
                                                <Link onClick={() => openModal(product)} className="btn">
                                                    {Language('Купити', 'Купить')}
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {!filterProducts(sortableProductsData).length && (
                                <h4 style={{ textAlign: 'center' }}>{Language('Каталог в процесі наповнення!', 'Каталог в процессе наполнения!')}</h4>
                            )}
                            <div className="catalog_box_more">
                                <button
                                    type="submit"
                                    onClick={loadMore}
                                    disabled={!filterProducts(sortableProductsData).length || !(currentPage < totalPageCount)}
                                    className="btn color submit">
                                    {Language(BUTTON_TEXT?.content?.UA?.title, BUTTON_TEXT?.content?.RU?.title)}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
