import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { PopularMaterials } from './Main/PopularMaterials';
import { VideoWorks } from './Main/VideoWorks';
import { Advantages } from './Main/Advantages';
import { Catalog } from './Main/Catalog';
import { Bots } from './Main/Bots';
import { Stages } from './Main/Stages';
import { Reviews } from './Main/Reviews';
import { Company } from './Main/Company';
import { Brands } from './Main/Brands';
import { Contacts } from './Main/Contacts';
import { FabricCatalog } from './Main/FabricCatalog';

Modal.setAppElement('#root'); // Викликайте лише один раз

export const App = () => {
    const [templateData, setTemplateData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const fetchTemplateData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`https://server.zanaves.shop/api/templates`);
            if (data) {
                setLoading(false);
                setTemplateData(data);
                return data;
            } else {
                setLoading(false);
                return;
            }
        } catch (e) {
            setLoading(false);
            return;
        }
    };

    const [productsData, seProductsData] = useState([]);
    const [isLoadingProducts, setLoadingProducts] = useState(true);
    const fetchProductseData = async () => {
        setLoadingProducts(true);
        try {
            const { data } = await axios.get('https://server.zanaves.shop/api/products');
            if (data) {
                setLoadingProducts(false);
                seProductsData(data);
                return data;
            } else {
                setLoadingProducts(false);
                return;
            }
        } catch (e) {
            setLoadingProducts(false);
            return;
        }
    };

    useEffect(() => {
        fetchTemplateData();
        fetchProductseData();
    }, []);

    const findSection = (sectionId) => templateData[0]?.sections.find((el) => el._id === sectionId);

    const HEADER = findSection('65294edd14410a5819b54d27');
    const HEADER_SLIDER = findSection('65298d8fba63b8929f4c1878');
    const HEADER_ADVANTAGES = findSection('652995a9ba63b8929f4c18d0');
    const POPULAR_MATERIALS = findSection('652bdb5cba63b8929f4c1ad5');
    const VIDEO_WORKS = findSection('652a410eba63b8929f4c1923');
    const ADVANTAGES = findSection('652be562ba63b8929f4c1ae4');
    const CATALOG = findSection('652bee4aba63b8929f4c1b28');
    const BOTS = findSection('652bfefaba63b8929f4c1b68');
    const STAGES = findSection('652cf436ba63b8929f4c1b8e');
    const STAGES_NO_INSTALL = findSection('652cf3fdba63b8929f4c1b8a');
    const STAGES_INSTALL = findSection('652cf696ba63b8929f4c1ba8');
    const REVIEWS = findSection('652d0432ba63b8929f4c1bd1');
    const COMPANY = findSection('652d0d25ba63b8929f4c1bff');
    const BRANDS = findSection('652d1589ba63b8929f4c1c1c');
    const CONTACTS = findSection('652d1760ba63b8929f4c1c35');
    const FABRICCATALOG = findSection('652d5990ba63b8929f4c1cd5');
    const FOOTER = findSection('652d6214ba63b8929f4c1cf3');

    // ==== GET LOCATION AND IP ====
    const [locationInfo, setLocationInfo] = useState('');

    useEffect(() => {
        getLocationInfo();
    }, []);

    const getLocationInfo = async () => {
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            const data = response.data;
            setLocationInfo(data.state);
            visit(data.IPv4);
            return null;
        } catch (error) {
            console.error('Помилка отримання інформації про місце розташування:', error);
            return null;
        }
    };
    // ==== ./GET LOCATION AND IP ====

    // ===== VISITS =====
    const visit = async (ip) => {
        setLoadingProducts(true);
        try {
            await axios.post('https://server.zanaves.shop/api/visit', {
                ip,
                whence: 'landing',
                note: '',
            });
            return;
        } catch (e) {
            return;
        }
    };
    // ./VISITS

    if (isLoading)
        return (
            <div className="preloader">
                <div className="preloader__text">Завантажуємо каталог...</div>
            </div>
        );

    return (
        <>
            <Header HEADER={HEADER} HEADER_SLIDER={HEADER_SLIDER} HEADER_ADVANTAGES={HEADER_ADVANTAGES} />

            <main>
                <PopularMaterials POPULAR_MATERIALS={POPULAR_MATERIALS} productsData={productsData} isLoadingProducts={isLoadingProducts} />
                <VideoWorks VIDEO_WORKS={VIDEO_WORKS} />
                <Advantages ADVANTAGES={ADVANTAGES} />
                <Catalog CATALOG={CATALOG} productsData={productsData} isLoadingProducts={isLoadingProducts} />
                <Bots BOTS={BOTS} />
                <Stages STAGES={STAGES} STAGES_NO_INSTALL={STAGES_NO_INSTALL} STAGES_INSTALL={STAGES_INSTALL} />
                <Reviews REVIEWS={REVIEWS} />
                <Company COMPANY={COMPANY} />
                <Brands BRANDS={BRANDS} />
                <Contacts CONTACTS={CONTACTS} locationInfo={locationInfo} />
                <FabricCatalog FABRICCATALOG={FABRICCATALOG} />
            </main>
            <Footer FOOTER={FOOTER} />
        </>
    );
};
